import { useShallow } from "zustand/shallow";

import {
  addNewOrganization,
  setCurrentOrganization,
  useAuthStore,
} from "../stores/auth-store";

export function useOrganization() {
  return useAuthStore(useShallow((state) => state.organization));
}

export function useOrganizations() {
  return useAuthStore(useShallow((state) => state.organizations));
}

export function useUser() {
  return useAuthStore(useShallow((state) => state.user));
}

export function useUserObject() {
  return useAuthStore(useShallow((state) => state.userObject));
}

export function useAddNewOrganization() {
  return addNewOrganization;
}

export function useSetCurrentOrganization() {
  return setCurrentOrganization;
}

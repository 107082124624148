import { isEmpty } from "remeda";

import type * as APITypes from "./api-types";
import { APIUtilities } from "./api-util";

class ConduitAPI extends APIUtilities {
  constructor() {
    super();
  }

  /**
   * Routes
   */

  /**
   * /v1/user
   */

  public async loadUser() {
    const resp = await this.get<APITypes.GetUserResponse>("/v1/user");

    /**
     * It seems, sometimes the /v1/user endpoint returns a 200 with an empty body or an empty user key
     */
    if (!resp || isEmpty(resp.user as unknown as Record<string, never>)) {
      throw new Error("/v1/user returned an invalid payload");
    }

    return resp;
  }

  public async loadUserInvites() {
    return this.get<APITypes.LoadUserInvitesResponse>("/v1/user/listInvites");
  }

  /**
   * /v1/network
   */

  public createNetwork(request: APITypes.CreateNetworkRequest) {
    return this.post<APITypes.CreateNetworkResponse>(
      "/v1/network/create",
      request,
    );
  }

  public getEstimateDeploymentCost(
    request: APITypes.EstimateDeploymentCostRequest,
  ) {
    return this.post<APITypes.EstimateDeploymentCostResponse>(
      "/public/estimateDeploymentCost",
      request,
    );
  }

  public getEstimateRecurringDACost(
    request: APITypes.EstimateRecurringDACostRequest,
  ) {
    return this.post<APITypes.EstimateRecurringDACostResponse>(
      "/public/estimateRecurringDACost",
      request,
    );
  }

  public listNetworks(request: APITypes.ListNetworksRequest) {
    return this.post<APITypes.ListNetworksResponse>(
      "/v1/network/list",
      request,
    );
  }

  public getNetwork(request: APITypes.GetNetworkRequest, signal?: AbortSignal) {
    return this.post<APITypes.GetNetworkResponse>(
      "/v1/network/get",
      request,
      signal,
    );
  }

  public deleteTestnet(request: APITypes.DeleteTestnetRequest) {
    return this.post("/v1/network/delete", request);
  }

  public updateTestnet(request: APITypes.UpdateNetworkRequest) {
    return this.post<APITypes.UpdateNetworkResponse>(
      "/v1/network/update",
      request,
    );
  }

  public updateDomains(request: APITypes.UpdateNetworkDomainsRequest) {
    return this.post<APITypes.UpdateNetworkDomainsResponse>(
      "/v1/network/updateDomains",
      request,
    );
  }

  public getCustomizations(
    request: APITypes.GetNetworkCustomizationSettingsRequest,
  ) {
    return this.post<APITypes.GetNetworkCustomizationSettingsResponse>(
      "/v1/network/getCustomizations",
      request,
    );
  }

  public updateCustomizations(
    request: APITypes.UpdateNetworkCustomizationSettingsRequest,
  ) {
    return this.post<APITypes.UpdateNetworkCustomizationSettingsResponse>(
      "/v1/network/updateCustomizations",
      request,
    );
  }

  public createUploadUrl(request: APITypes.CreateUploadUrlRequest) {
    return this.post<APITypes.CreateUploadUrlResponse>(
      "/v1/network/createUploadUrl",
      request,
    );
  }

  public getNetworkGraphData(request: APITypes.NetworkGraphDataRequest) {
    return this.post<APITypes.NetworkGraphDataResponse>(
      "/v1/network/getGraphData",
      request,
    );
  }

  public getChainIdUnique() {
    return this.post<APITypes.GetChainIdUniqueResponse>(
      "/public/getChainIdUnique",
      {},
    );
  }

  public isChainIdUnique(
    request: APITypes.IsChainIdUniqueRequest,
    signal?: AbortSignal,
  ) {
    return this.post<APITypes.IsChainIdUniqueResponse>(
      "/public/isChainIdUnique",
      request,
      signal,
    );
  }

  public getNetworkStatus(request: APITypes.GetNetworkStatusRequest) {
    return this.post<APITypes.GetNetworkStatusResponse>(
      "/v1/network/status",
      request,
    );
  }

  public getNetworkComponentStatus(
    request: APITypes.GetNetworkComponentStatusRequest,
  ) {
    return this.post<APITypes.GetNetworkComponentStatusResponse>(
      "/v1/network/component/status",
      request,
    );
  }

  public constructNetworkComponentLogsRequests({
    network,
    name,
    container,
    organization,
  }: {
    network: string;
    name: string;
    container: string;
    organization: string;
  }) {
    const url = new URL(`/v1/network/component/logs`, this.apiHost);

    url.searchParams.append("id", network);
    url.searchParams.append("org", organization);
    url.searchParams.append("name", name);
    url.searchParams.append("container", container);

    return {
      url: url.toString(),
      options: {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      } satisfies RequestInit,
    };
  }

  public constructPrestartJobLogsRequests({
    network,
    organization,
  }: {
    network: string;
    organization: string;
  }) {
    const url = new URL(`/v1/network/prestartJobLogs`, this.apiHost);

    url.searchParams.append("id", network);
    url.searchParams.append("org", organization);

    return {
      url: url.toString(),
      options: {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      } satisfies RequestInit,
    };
  }

  /**
   * /v1/organization
   */

  public createOrganization(request: APITypes.CreateOrganizationRequest) {
    return this.post<APITypes.CreateOrganizationResponse>(
      "/v1/organization/create",
      request,
    );
  }

  public listOrganizationMembers(
    request: APITypes.ListOrganizationMembersRequest,
  ) {
    return this.post<APITypes.ListOrganizationMembersResponse>(
      "/v1/organization/members/list",
      request,
    );
  }

  public removeOrganizationMember(
    request: APITypes.RemoveOrganizationMemberRequest,
  ) {
    return this.post<APITypes.RemoveOrganizationMemberResponse>(
      "/v1/organization/members/remove",
      request,
    );
  }

  public updateUserOrganizationRole(
    request: APITypes.UpdateUserOrganizationRequest,
  ) {
    return this.post<APITypes.UpdateUserOrganizationResponse>(
      "/v1/organization/members/updateRole",
      request,
    );
  }

  public revokeOrganizationInvite(
    request: APITypes.RevokeOrganizationInviteRequest,
  ) {
    return this.post<APITypes.RevokeOrganizationInviteReponse>(
      "/v1/organization/invites/revoke",
      request,
    );
  }

  public getOrganizationInviteInfo(
    request: APITypes.OrganizationInviteInfoRequest,
  ) {
    return this.post<APITypes.OrganizationInviteInfoResponse>(
      "/v1/organization/invites/getInfo",
      request,
    );
  }

  public getPricing(request: APITypes.GetPricingRequest) {
    return this.post<APITypes.GetPricingResponse>(
      "/v1/organization/pricing",
      request,
    );
  }

  public changeSubscription(request: APITypes.ChangeSubscriptionRequest) {
    return this.post<void>("/v1/organization/subscriptions/change", request);
  }

  public createSubscriptionRequest(
    request: APITypes.CreateSubscriptionRequest,
  ) {
    return this.post<APITypes.CreateSubscriptionResponse>(
      "/v1/organization/subscriptions/create",
      request,
    );
  }

  public listOrganizationDomains(
    request: APITypes.ListOrganizationDomainsRequest,
  ) {
    return this.post<APITypes.ListOrganizationDomainsResponse>(
      "/v1/organization/domains/list",
      request,
    );
  }

  public deleteOrganizationDomain(
    request: APITypes.DeleteOrganizationDomainRequest,
  ) {
    return this.post<unknown>("/v1/organization/domains/delete", request);
  }

  public verifyOrganizationDomain(
    request: APITypes.VerifyOrganizationDomainRequest,
  ) {
    return this.post<APITypes.VerifyOrganizationDomainResponse>(
      "/v1/organization/domains/verify",
      request,
    );
  }

  public createOrganizationDomain(
    request: APITypes.CreateOrganizationDomainRequest,
  ) {
    return this.post("/v1/organization/domains/create", request);
  }

  public createOrganizationInvites(
    request: APITypes.CreateOrganizationInviteRequest,
  ) {
    return this.post("/v1/organization/invites/create", request);
  }

  public redeemOrganizationInvite(
    request: APITypes.RedeemOrganizationInviteRequest,
  ) {
    return this.post<APITypes.RedeemOrganizationInviteResponse>(
      "/v1/organization/invites/redeem",
      request,
    );
  }

  public handleCustomerPortal(request: APITypes.GetCustomerPortalRequest) {
    return this.post<APITypes.GetCustomerPortalResponse>(
      "/v1/organization/billing/handleCustomerPortal",
      request,
    );
  }

  /**
   * /v1/rpckey
   */

  public setUsageLimit(request: APITypes.SetUsageLimitsRequest) {
    return this.post<APITypes.SetUsageLimitsResponse>(
      "/v1/rpckey/setUsageLimits",
      request,
    );
  }

  public getUsageLimit(request: APITypes.GetUsageLimitsRequest) {
    return this.post<APITypes.GetUsageLimitsResponse>(
      "/v1/rpckey/getUsageLimits",
      request,
    );
  }

  public updateRpcKey(request: APITypes.UpdateRpcKeyRequest) {
    return this.post<APITypes.UpdateRpcKeyResponse>(
      "/v1/rpckey/update",
      request,
    );
  }

  public createRpcKey(request: APITypes.CreateRpcKeyRequest) {
    return this.post<APITypes.CreateRpcKeyResponse>(
      "/v1/rpckey/create",
      request,
    );
  }

  public setRpcKeyEndpoints(request: APITypes.SetRpcKeyEndpointsRequest) {
    return this.post<APITypes.SetRpcKeyEndpointsResponse>(
      "/v1/rpckey/setEndpoints",
      request,
    );
  }

  public deleteRpcKey(request: APITypes.DeleteRpcKeyRequest) {
    return this.post<APITypes.DeleteRpcKeyResponse>(
      "/v1/rpckey/delete",
      request,
    );
  }

  public listRpcKeys(request: APITypes.ListRpcKeysRequest) {
    return this.post<APITypes.ListRpcKeysResponse>("/v1/rpckey/list", request);
  }

  public getRpcKeyEndpoints(request: APITypes.GetRpcKeyEndpointsRequest) {
    return this.post<APITypes.GetRpcKeyEndpointsResponse>(
      "/v1/rpckey/listEndpoints",
      request,
    );
  }

  public recreateRpcKey(request: APITypes.RecreateRpcKeyRequest) {
    return this.post<APITypes.RpcKeyResponse>("/v1/rpckey/recreate", request);
  }

  public listAllEndpoints(request: APITypes.ListAllEndpointsRequest) {
    return this.post<APITypes.ListAllEndpointsResponse>(
      "/v1/rpckey/endpoints",
      request,
    );
  }

  /**
   * /v1/integration
   */

  public listIntegrations(request: APITypes.ListIntegrationsRequest) {
    return this.post<APITypes.ListIntegrationsResponse>(
      "/v1/integration/list",
      request,
    );
  }

  public getIntegrationOauthEndpoint(
    request: APITypes.GetIntegrationAuthEndpointRequest,
  ) {
    return this.post<APITypes.GetIntegrationAuthEndpointResponse>(
      "/v1/integration/getAuthEndpoint",
      request,
    );
  }

  public installIntegration(request: APITypes.InstallIntegrationRequest) {
    return this.post<APITypes.InstallIntegrationResponse>(
      "/v1/integration/install",
      request,
    );
  }

  public uninstallIntegration(request: APITypes.UninstallIntegrationRequest) {
    return this.post<APITypes.UninstallIntegrationResponse>(
      "/v1/integration/uninstall",
      request,
    );
  }

  public acceptMarketplaceTerms(
    request: APITypes.AcceptMarketplaceTermsRequest,
  ) {
    return this.post<APITypes.AcceptMarketplaceTermsResponse>(
      "/v1/integration/acceptTerms",
      request,
    );
  }

  public getIntegrationPrestartJobs(
    request: APITypes.GetIntegrationPrestartJobsRequest,
  ) {
    return this.post<APITypes.GetIntegrationPrestartJobsResponse>(
      "/v1/integration/prestartJobs",
      request,
    );
  }

  public submitIntegrationConfiguration(
    request: APITypes.SubmitIntegrationConfigurationRequest,
  ) {
    return this.post<APITypes.SubmitIntegrationConfigurationResponse>(
      "/v1/integration/submitConfigurationInputs",
      request,
    );
  }

  public getIntegrationPaymentInfo(
    request: APITypes.GetIntegrationPaymentInfoRequest,
  ) {
    return this.post<APITypes.GetIntegrationPaymentInfoResponse>(
      "/v1/integration/paymentInfo",
      request,
    );
  }

  /**
   * Public Routes
   */

  public getPublishedTestnet(request: APITypes.GetPublishedRequest) {
    return this.post<APITypes.GetPublishedResponse>(
      "/public/network/getPublished",
      request,
    );
  }

  /**
   * @note returns bytes
   * @see https://github.com/conduitxyz/conduit/blob/main/server/cmd/api/network.go#L2199
   */
  public getTestnetBootnodes(slug: string) {
    return this.get<string>(`/public/network/bootnodes/${slug}`);
  }

  public getTestnetStaticPeers(slug: string) {
    return this.get<string>(`/public/network/staticPeers/${slug}`);
  }

  public getTestnetConfigFiles(slug: string) {
    return this.get<APITypes.TestnetConfigFilesResponse>(
      `/public/network/files/${slug}`,
    );
  }

  public dripEth(request: APITypes.DripEthRequest) {
    return this.post<APITypes.DripEthResponse>("/v1/faucet/dripEth", request);
  }
}

export const conduitAPI = new ConduitAPI();

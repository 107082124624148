import { Outlet } from "react-router";

import { pb } from "../../lib/pb";
import CustomerBillingSidebarNavLink from "../../shared/customer-billing-sidebar-nav-link";
import SidebarNavLink, {
  SidebarNavLinkWrapper,
} from "../../shared/SidebarNavLink";

export default function OrganizationLayout() {
  return (
    <div className="mt-10 px-5 pb-5 lg:px-10 lg:pb-10">
      <div className="mx-auto w-full max-w-7xl">
        <div className="mb-10">
          <h1 className="text-system-xxl text-black">Organization settings</h1>
        </div>

        <div className="grid gap-5 md:grid-cols-[minmax(0,_13.75rem)_1fr] lg:gap-12 xl:gap-24">
          <SidebarNavLinkWrapper>
            <SidebarNavLink to={pb.organizationSettings()}>
              Access
            </SidebarNavLink>

            <CustomerBillingSidebarNavLink />
          </SidebarNavLinkWrapper>

          <div className="rounded-xl border-0.5 bg-white p-5 md:p-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router";

import { PylonSupportForm } from "../constants/urls";
import { Button } from "../shared/Button";
import { Panel } from "../shared/Panel";
import SystemLayout from "../shared/SystemLayout";

export default function ErrorPage() {
  const error = useRouteError() as {
    statusText?: string;
    message?: string;
  };

  useEffect(() => {
    captureException(error, {
      level: "fatal",
      extra: { "Error Page": true },
    });
  }, [error]);

  return (
    <SystemLayout>
      <Panel className="pb-5 text-center">
        <h1 className="mb-3 text-system-xl">500 Error</h1>

        <p className="mb-4 text-system-m text-grey-70">
          {error?.statusText ??
            error?.message ??
            "Sorry, an unexpected error has occurred"}
        </p>

        <Button
          className="mb-2 w-full"
          onClick={() => window.location.reload()}
        >
          Try again
        </Button>

        <Button asChild variant="secondary" className="mb-4 w-full">
          <a href="/deployments">Back home</a>
        </Button>

        <p className="text-system-m text-grey-70">
          If this error persists, please{" "}
          <a
            target="_blank"
            rel="nofollow"
            href={PylonSupportForm}
            className="font-medium"
          >
            contact support
          </a>
          .
        </p>
      </Panel>
    </SystemLayout>
  );
}

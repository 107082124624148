import type { User as FirebaseUser } from "firebase/auth";
import { filter, isEmpty, omit, pipe } from "remeda";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { useShallow } from "zustand/shallow";

import type { GetUserResponse, Organization } from "../apis/api-types";
import { CURRENT_ORGANIZATION } from "../constants/localStorage";
import { isUser } from "../utils/assertUser";

export const Experimental_UserStatus = {
  UNKNOWN: "UNKNOWN",
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
} as const;

type EmptyObject = Record<PropertyKey, never>;

type AuthStore = {
  /**
   * DO NOT USE
   */
  experimental_status: (typeof Experimental_UserStatus)[keyof typeof Experimental_UserStatus];
  email: string;
  name: string;
  organization: Organization | EmptyObject;
  organizations: Organization[];
  user: FirebaseUser | EmptyObject | null;
  userObject: GetUserResponse | EmptyObject;
};

export const initialState: AuthStore = {
  email: "",
  name: "",
  organization: {},
  organizations: [],
  experimental_status: Experimental_UserStatus.UNKNOWN,
  user: {},
  userObject: {},
};

export const useAuthStore = create<AuthStore>()(
  devtools(() => ({ ...initialState })),
);

/**
 * Actions
 */

export const clearUserData = () => {
  try {
    window.localStorage.removeItem(CURRENT_ORGANIZATION);
  } catch (err) {
    console.error(err);
  }

  useAuthStore.setState(initialState);
};

export const resetAuthStoreWithoutUser = () => {
  try {
    window.localStorage.removeItem(CURRENT_ORGANIZATION);
  } catch (err) {
    console.error(err);
  }

  useAuthStore.setState(pipe(initialState, omit(["user"])));
};

export const addNewOrganization = (value: Organization) => {
  useAuthStore.setState((state) => {
    if (
      isEmpty(
        pipe(
          state.organizations,
          filter((el) => el.organization === value.organization),
        ),
      )
    ) {
      return { organizations: [...state.organizations, value] };
    }

    return {};
  });
};

export const setCurrentOrganization = (value: Organization | EmptyObject) => {
  try {
    if (isEmpty(value as Record<string, never>)) {
      window.localStorage.removeItem(CURRENT_ORGANIZATION);
    } else {
      window.localStorage.setItem(CURRENT_ORGANIZATION, value.organization);
    }
  } catch (err) {
    console.error(err);
  }

  useAuthStore.setState({ organization: value });
};

/**
 * Composed Hooks
 */

export function useUserIsLoggedIn() {
  return useAuthStore(
    useShallow(
      (state) =>
        isUser(state.user) &&
        !isEmpty(state.userObject as Record<string, unknown>),
    ),
  );
}

export function useUserUUID(): string | undefined {
  return useAuthStore(
    useShallow((state) => state?.userObject?.user?.user ?? undefined),
  );
}

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useEffect } from "react";
import { Outlet, ScrollRestoration } from "react-router";
import { Toaster } from "sonner";

import { useAuth } from "../hooks/use-auth";
import { useTitle } from "../hooks/use-title";
import { queryClient } from "../lib/query/client";
import { VercelAnalytics } from "../lib/vercel";

export default function RootLayout() {
  useAuth();

  const title = useTitle();
  useEffect(() => {
    document.title = title ? `${title} | Conduit` : "Conduit";
  }, [title]);

  return (
    <QueryClientProvider client={queryClient}>
      <Outlet />

      <Toaster />

      <ScrollRestoration />

      <VercelAnalytics />

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

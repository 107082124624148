import { useMatches } from "react-router";

export type CrumbFunc = (m: MatchWithCrumb) => string;

type MatchWithCrumb = ReturnType<typeof useMatches>[number] & {
  handle: { crumb: string | CrumbFunc };
};

export const useTitle = () =>
  (useMatches() as MatchWithCrumb[])
    .filter((m) => Boolean(m.handle?.crumb))
    .map((m) =>
      typeof m.handle.crumb === "function" ? m.handle.crumb(m) : m.handle.crumb,
    )
    .reverse()
    .join(" — ");

import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import * as Popover from "@radix-ui/react-popover";
import { track } from "@vercel/analytics";
import { useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router";
import useMeasure from "react-use-measure";
import { isEmpty } from "remeda";

import type { Organization } from "../../apis/api-types";
import {
  useOrganization,
  useOrganizations,
  useSetCurrentOrganization,
} from "../../context/hooks";
import useListTestnets from "../../hooks/api/useListTestnets";
import { pb } from "../../lib/pb";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { PlusCircleIconAlt } from "../Icons";
import NetworkAvatar from "../NetworkAvatar";
import Placeholder from "../Placeholder";
import SquareAvatar from "../SquareAvatar";
import { OrganizationItem, OrganizationItemSkeleton } from "./OrganizationItem";
import { RollupItem, RollupItemSkeleton } from "./RollupItem";

export default function AppSwitcher() {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const organization = useOrganization();
  const organizations = useOrganizations();
  const setCurrentOrganization = useSetCurrentOrganization();

  function switchOrganization(org: Organization) {
    return () => {
      track("Click Switch organization");

      setCurrentOrganization(org);

      if (pathname.includes("nodes")) {
        navigate(pb.nodes());
      } else {
        navigate(pb.deployments());
      }
    };
  }

  const fallback = useMemo(() => {
    return organization.name ? organization.name.slice(0, 1).toUpperCase() : "";
  }, [organization.name]);

  const { data } = useListTestnets();

  const {
    /**
     * @note This is currently the slug
     */
    id,
  } = useParams();

  const activeNetwork = useMemo(
    () =>
      typeof id === "string" && data
        ? data.find((datum) => datum.slug === id)
        : undefined,
    [data, id],
  );

  const [ref, { width }] = useMeasure();

  return (
    <Popover.Root>
      {activeNetwork ? (
        <div className="flex h-9 select-none items-stretch divide-x-0.5 overflow-hidden rounded-lg border-0.5 bg-grey-0">
          <Link
            to={pb.deployments()}
            className="flex items-center pl-2.5 pr-1.5 text-black"
            ref={ref}
          >
            {organization.name ? (
              <>
                <SquareAvatar
                  className="mr-2 size-[1.125rem] rounded-sm text-[11px]"
                  fallback={fallback}
                />

                <span className="mr-3 truncate text-system-m">
                  {organization.name}
                </span>
              </>
            ) : (
              <>
                <div className="skeleton-loader mr-2 size-[1.125rem] shrink-0 rounded-sm" />

                <div className="skeleton-loader my-1 mr-3 h-3.5 w-32 rounded" />
              </>
            )}
          </Link>

          <Popover.Trigger asChild>
            <button
              className="group flex items-center justify-center bg-white pl-2.5 pr-1.5"
              aria-label={`Open ${activeNetwork.name} organization and deployment switcher`}
              test-id="AppSwitcher"
            >
              <NetworkAvatar
                network={activeNetwork.network}
                logoUrl={activeNetwork.logoUrl}
                className="mr-2 size-[1.125rem]"
              />

              <span className="mr-3 max-w-[16ch] truncate text-system-m">
                {activeNetwork.name}
              </span>

              <IconButton asChild>
                <div>
                  <span className="sr-only">Switch organization</span>
                  <ChevronUpDownIcon className="size-4 text-grey-60" />
                </div>
              </IconButton>
            </button>
          </Popover.Trigger>
        </div>
      ) : (
        <Popover.Trigger asChild>
          <button
            className="group flex h-9 select-none items-stretch divide-x-0.5 overflow-hidden rounded-lg border-0.5 bg-grey-0"
            aria-label={`Open ${organization.name} organization switcher`}
            test-id="AppSwitcher"
          >
            <div className="flex items-center pl-2.5 pr-1.5">
              {organization.name ? (
                <>
                  <SquareAvatar
                    className="mr-2 size-[1.125rem] rounded-sm text-[11px]"
                    fallback={fallback}
                  />

                  <span className="mr-3 truncate text-system-m">
                    {organization.name}
                  </span>
                </>
              ) : (
                <>
                  <div className="skeleton-loader mr-2 h-[1.125rem] w-[1.125rem] shrink-0 rounded-sm" />

                  <div className="skeleton-loader my-1 mr-3 h-3.5 w-32 rounded" />
                </>
              )}

              <div className="flex h-6 w-6 items-center justify-center rounded group-hover:bg-grey-20">
                <ChevronUpDownIcon className="size-4 text-grey-60" />
              </div>
            </div>
          </button>
        </Popover.Trigger>
      )}

      <Popover.Portal>
        <Popover.Content
          className="z-50 grid w-[33.5rem] grid-cols-2 divide-x-0.5 overflow-hidden rounded-lg border-0.5 bg-grey-0 shadow-switcher will-change-[transform,opacity] data-[state=open]:animate-appSwitcherSlideUp"
          sideOffset={4}
          align="start"
          side="bottom"
          alignOffset={activeNetwork ? width * -1 : undefined}
          style={{
            transformOrigin: "var(--radix-popover-content-transform-origin)",
          }}
          test-id="AppSwitcherPopover"
        >
          <div className="peer flex flex-col transition-colors focus-within:bg-white hover:bg-white peer-focus-within:bg-grey-0 peer-hover:bg-grey-0">
            <AppSwitcherSection label="Organizations">
              {isEmpty(organization as Record<string, unknown>) ? (
                <div className="flex-1">
                  <Placeholder count={2}>
                    <OrganizationItemSkeleton />
                  </Placeholder>
                </div>
              ) : (
                <ul className="flex-1">
                  {organizations.map((el) => (
                    <li key={el.organization}>
                      <Popover.Close asChild>
                        <OrganizationItem
                          onClick={switchOrganization(el)}
                          organization={el}
                          isActive={organization === el}
                        />
                      </Popover.Close>
                    </li>
                  ))}
                </ul>
              )}
            </AppSwitcherSection>

            <div className="mt-auto px-2 pb-2">
              <Popover.Close asChild>
                <Button variant="secondary" className="w-full gap-1.5" asChild>
                  <Link to={pb.createOrganization()}>
                    <PlusCircleIconAlt className="size-4" />

                    {"Create organization"}
                  </Link>
                </Button>
              </Popover.Close>
            </div>
          </div>

          <div className="peer flex flex-col transition-colors focus-within:bg-white hover:bg-white peer-focus-within:bg-grey-0 peer-hover:bg-grey-0">
            <AppSwitcherSection label="Deployments">
              {data ? (
                isEmpty(data) ? (
                  <div className="flex flex-1 items-center justify-center">
                    <p className="text-center text-system-m text-black">
                      No deployments yet
                    </p>
                  </div>
                ) : (
                  <ul className="flex-1">
                    {data.map((el) => (
                      <li key={el.network}>
                        <Popover.Close asChild>
                          <RollupItem
                            network={el}
                            to={pb.dashboardOverview({ slugOrId: el.slug })}
                            isActiveNetwork={
                              activeNetwork?.network === el.network
                            }
                          />
                        </Popover.Close>
                      </li>
                    ))}
                  </ul>
                )
              ) : (
                <ul className="flex-1">
                  <Placeholder count={3}>
                    <RollupItemSkeleton />
                  </Placeholder>
                </ul>
              )}
            </AppSwitcherSection>

            <div className="mt-auto px-2 pb-2">
              <Popover.Close asChild>
                <Button
                  variant={data && data.length === 0 ? "primary" : "secondary"}
                  className="w-full gap-1.5"
                  asChild
                >
                  <Link to={pb.deploy()}>
                    <PlusCircleIconAlt className="size-4" />

                    {"New deployment"}
                  </Link>
                </Button>
              </Popover.Close>
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function AppSwitcherSection({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) {
  return (
    <div className="flex max-h-[calc(40px*7.65+32px)] w-full flex-1 flex-col overflow-y-scroll">
      <div className="flex flex-1 shrink-0 flex-col p-2 pt-3">
        <div className="px-2">
          <p className="text-system-s text-grey-80">{label}</p>
        </div>

        {children}
      </div>
    </div>
  );
}

import { Outlet } from "react-router";

import AppFooter from "../shared/AppFooter";
import { AppNav, AppTopLevelNav } from "../shared/AppNav";
import AppSwitcher from "../shared/AppSwitcher";

export default function BaseLayout() {
  return (
    <>
      <AppTopLevelNav className="bg-white" slot={<AppSwitcher />} />

      <AppNav />

      <Outlet />

      <AppFooter />
    </>
  );
}
